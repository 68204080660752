<template>
  <div id="flightList" class="Content-Page">
    <div id="calendarDATA" class="wrap-Main">
      <div class="box-S2">
        <CompListFlight ref="CompListFlight" :DataDateFromCalendar="datadatefromcalender" :Service="'flightselect'">
        </CompListFlight>
      </div>
      <div class="box-S2">
        <calendar :Service="'flightselect'" @DataCalender="BindingDataFromCalendar"></calendar>
      </div>
    </div>
    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import calendar from "../components/main/Calendar";
import CompListFlight from "../components/main/CompList";

export default {
  data: () => ({
    userdata: null,
    datadatefromcalender: new Date().toISOString().substr(0, 10),
  }),
  components: {
    calendar,
    CompListFlight,
  },
  async mounted() {
    this.userdata = await JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    Back() {
      this.$router.go(-1);
    },
    async BindingDataFromCalendar(dataDate) {
      this.datadatefromcalender = dataDate;
      await this.$refs.CompListFlight.renderListFlight(dataDate);
    },
  },
};
</script>

<style>
</style>